import React from 'react'

const TelegramIcon = () => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_296_13)'>
        <path
          d='M17.0011 34.0022C26.3906 34.0022 34.0022 26.3906 34.0022 
     17.0011C34.0022 7.61166 26.3906 0 17.0011 0C7.61166 0 0 7.61166 0 
     17.0011C0 26.3906 7.61166 34.0022 17.0011 34.0022Z'
          fill='url(#paint0_linear_296_13)'
        />
        <path
          d='M11.373 18.3633L13.3298 23.6683L22.0099 11.6108L11.373 18.3633Z'
          fill='#C2DAEC'
        />
        <path
          d='M13.543 19.5414L13.3568 23.7221L17.9369 19.8354L13.543 19.5414Z'
          fill='#9FCDE2'
        />
        <path
          d='M24.1328 9.57323L7.02172 16.0766C6.53925 16.2606 6.55047 
     16.9495 7.04192 17.1133L11.4538 18.6034L21.5005 12.1203L13.543 
     19.5414L19.7389 24.2428C20.383 24.732 21.3187 24.4021 21.514 
     23.6189L24.8621 10.224C24.9698 9.79315 24.548 9.41614 24.1306 9.57547L24.1328 9.57323Z'
          fill='#ECF8FD'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_296_13'
          x1='4.97961'
          y1='4.97961'
          x2='29.0204'
          y2='29.0204'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#00AFE5' />
          <stop offset='1' stopColor='#0094D4' />
        </linearGradient>
        <clipPath id='clip0_296_13'>
          <rect width='34' height='34' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export default TelegramIcon
